/* http://meyerweb.com/eric/tools/css/reset/
   v2.0-modified | 20110126
   License: none (public domain)
*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* make sure to set some focus styles for accessibility */
:focus {
  outline: 0; }

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block; }

body {
  line-height: 1; }

ol,
ul {
  list-style: none; }

blockquote,
q {
  quotes: none; }

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  -webkit-appearance: none;
  -moz-appearance: none; }

input[type="search"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  box-sizing: content-box; }

textarea {
  overflow: auto;
  vertical-align: top;
  resize: vertical; }

/**
 * Correct `inline-block` display not defined in IE 6/7/8/9 and Firefox 3.
 */
audio,
canvas,
video {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  max-width: 100%; }

/**
 * Prevent modern browsers from displaying `audio` without controls.
 * Remove excess height in iOS 5 devices.
 */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
 * Address styling not present in IE 7/8/9, Firefox 3, and Safari 4.
 * Known issue: no IE 6 support.
 */
[hidden] {
  display: none; }

/**
 * 1. Correct text resizing oddly in IE 6/7 when body `font-size` is set using
 *    `em` units.
 * 2. Prevent iOS text size adjust after orientation change, without disabling
 *    user zoom.
 */
html {
  font-size: 100%;
  /* 1 */
  -webkit-text-size-adjust: 100%;
  /* 2 */
  -ms-text-size-adjust: 100%;
  /* 2 */ }

/**
 * Address `outline` inconsistency between Chrome and other browsers.
 */
a:focus {
  outline: thin dotted; }

/**
 * Improve readability when focused and also mouse hovered in all browsers.
 */
a:active,
a:hover {
  outline: 0; }

/**
 * 1. Remove border when inside `a` element in IE 6/7/8/9 and Firefox 3.
 * 2. Improve image quality when scaled in IE 7.
 */
img {
  border: 0;
  /* 1 */
  -ms-interpolation-mode: bicubic;
  /* 2 */ }

/**
 * Address margin not present in IE 6/7/8/9, Safari 5, and Opera 11.
 */
figure {
  margin: 0; }

/**
 * Correct margin displayed oddly in IE 6/7.
 */
form {
  margin: 0; }

/**
 * Define consistent border, margin, and padding.
 */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

/**
 * 1. Correct color not being inherited in IE 6/7/8/9.
 * 2. Correct text not wrapping in Firefox 3.
 * 3. Correct alignment displayed oddly in IE 6/7.
 */
legend {
  border: 0;
  /* 1 */
  padding: 0;
  white-space: normal;
  /* 2 */
  *margin-left: -7px;
  /* 3 */ }

/**
 * 1. Correct font size not being inherited in all browsers.
 * 2. Address margins set differently in IE 6/7, Firefox 3+, Safari 5,
 *    and Chrome.
 * 3. Improve appearance and consistency in all browsers.
 */
button,
input,
select,
textarea {
  font-size: 100%;
  /* 1 */
  margin: 0;
  /* 2 */
  vertical-align: baseline;
  /* 3 */
  *vertical-align: middle;
  /* 3 */ }

/**
 * Address Firefox 3+ setting `line-height` on `input` using `!important` in
 * the UA stylesheet.
 */
button,
input {
  line-height: normal; }

/**
 * Address inconsistent `text-transform` inheritance for `button` and `select`.
 * All other form control elements do not inherit `text-transform` values.
 * Correct `button` style inheritance in Chrome, Safari 5+, and IE 6+.
 * Correct `select` style inheritance in Firefox 4+ and Opera.
 */
button,
select {
  text-transform: none; }

/**
 * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
 *    and `video` controls.
 * 2. Correct inability to style clickable `input` types in iOS.
 * 3. Improve usability and consistency of cursor style between image-type
 *    `input` and others.
 * 4. Remove inner spacing in IE 7 without affecting normal text inputs.
 *    Known issue: inner spacing remains in IE 6.
 */
button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  /* 2 */
  cursor: pointer;
  /* 3 */
  *overflow: visible;
  /* 4 */ }

/**
 * Re-set default cursor for disabled elements.
 */
button[disabled],
html input[disabled] {
  cursor: default; }

/**
 * 1. Address box sizing set to content-box in IE 8/9.
 * 2. Remove excess padding in IE 8/9.
 * 3. Remove excess padding in IE 7.
 *    Known issue: excess padding remains in IE 6.
 */
input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */
  *height: 13px;
  /* 3 */
  *width: 13px;
  /* 3 */ }

/**
 * 1. Address `appearance` set to `searchfield` in Safari 5 and Chrome.
 * 2. Address `box-sizing` set to `border-box` in Safari 5 and Chrome
 *    (include `-moz` to future-proof).
 */
input[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  /* 2 */
  box-sizing: content-box; }

/**
 * Remove inner padding and search cancel button in Safari 5 and Chrome
 * on OS X.
 */
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

/**
 * Remove inner padding and border in Firefox 3+.
 */
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

/**
 * 1. Remove default vertical scrollbar in IE 6/7/8/9.
 * 2. Improve readability and alignment in all browsers.
 */
textarea {
  overflow: auto;
  /* 1 */
  vertical-align: top;
  /* 2 */ }

/**
 * Remove most spacing between table cells.
 */
table {
  border-collapse: collapse;
  border-spacing: 0; }

html,
button,
input,
select,
textarea {
  color: #222; }

::-moz-selection {
  background: #b3d4fc;
  text-shadow: none; }

::selection {
  background: #b3d4fc;
  text-shadow: none; }

img {
  vertical-align: middle; }

fieldset {
  border: 0;
  margin: 0;
  padding: 0; }

textarea {
  resize: vertical; }

.chromeframe {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0; }

@font-face {
  font-family: "Inter Regular";
  src: url(/static/media/Inter-Regular.d2576714.otf); }

@font-face {
  font-family: "Inter Bold";
  src: url(/static/media/Inter-Bold.3d991770.otf); }

@font-face {
  font-family: "Inter Medium";
  src: url(/static/media/Inter-Medium.fe616896.otf); }

body {
  background: #f5f5f5; }

.block-home {
  width: 100vw;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 6em;
  padding-bottom: 60px; }
  .block-home .block-home__logo-container {
    width: 100%;
    height: 260px; }
    .block-home .block-home__logo-container img {
      width: 100%;
      height: 100%;
      object-fit: contain; }
  .block-home .block-home__block-signatures {
    width: 80vw;
    height: auto;
    padding-top: 80px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center; }
    .block-home .block-home__block-signatures .signature {
      background: #f5f5f5;
      padding: 20px 25px;
      display: flex;
      flex-direction: column;
      text-align: center;
      border-radius: 5px;
      font-family: "Inter Bold", sans-serif;
      color: #424242;
      font-size: 20px;
      -webkit-transition: background 250ms linear, box-shadow 250ms linear, -webkit-transform 250ms ease-in-out;
      transition: background 250ms linear, box-shadow 250ms linear, -webkit-transform 250ms ease-in-out;
      transition: background 250ms linear, box-shadow 250ms linear, transform 250ms ease-in-out;
      transition: background 250ms linear, box-shadow 250ms linear, transform 250ms ease-in-out, -webkit-transform 250ms ease-in-out; }
      .block-home .block-home__block-signatures .signature:after {
        content: "-->";
        font-family: "Inter Medium", sans-serif;
        padding-top: 10px; }
      .block-home .block-home__block-signatures .signature:hover {
        background: #e0e0e0;
        box-shadow: 0px 10px 10px -1px rgba(0, 0, 0, 0.2);
        -webkit-transform: translateY(-8px);
                transform: translateY(-8px);
        -webkit-transition: background 250ms linear, box-shadow 250ms linear, -webkit-transform 250ms ease-in-out;
        transition: background 250ms linear, box-shadow 250ms linear, -webkit-transform 250ms ease-in-out;
        transition: background 250ms linear, box-shadow 250ms linear, transform 250ms ease-in-out;
        transition: background 250ms linear, box-shadow 250ms linear, transform 250ms ease-in-out, -webkit-transform 250ms ease-in-out; }
      .block-home .block-home__block-signatures .signature:not(:first-child) {
        margin-left: 20px; }
  .block-home .block-access-admin {
    margin-top: 80px; }
    .block-home .block-access-admin a {
      font-family: "Inter Medium", sans-serif;
      color: #424242;
      font-size: 16px;
      -webkit-transition: color 250ms linear;
      transition: color 250ms linear; }
      .block-home .block-access-admin a:hover {
        color: black;
        -webkit-transition: color 250ms linear;
        transition: color 250ms linear; }

.not-found {
  width: 1600px;
  margin: 0 auto;
  height: 100vh;
  display: flex;
  justify-content: center;
  flex-direction: column; }
  @media (max-width: 1450px) {
    .not-found {
      width: 85vw; } }
  .not-found .not-found__title {
    font-family: "Inter Bold", sans-serif;
    font-size: 36px;
    margin-bottom: 20px;
    color: #212121; }
  .not-found .not-found__subtitle {
    font-family: "Inter Regular", sans-serif;
    font-size: 18px; }

.block-admin {
  width: 1600px;
  margin: 0 auto;
  padding-top: 120px;
  padding-bottom: 80px; }
  @media (max-width: 1450px) {
    .block-admin {
      width: 80vw; } }
  .block-admin .block-admin__title {
    font-family: "Inter Bold", sans-serif;
    color: #212121;
    padding-bottom: 10px;
    font-size: 24px;
    border-bottom: 2px solid #212121;
    margin-bottom: 50px; }
  .block-admin .admin-signature {
    background: #e0e0e0;
    padding: 25px 35px;
    border-radius: 5px; }
    .block-admin .admin-signature:not(:last-child) {
      margin-bottom: 30px; }
    .block-admin .admin-signature .admin-signature__infos {
      margin-bottom: 25px; }
      .block-admin .admin-signature .admin-signature__infos .infos__name {
        font-family: "Inter Bold", sans-serif;
        color: #212121;
        font-size: 20px;
        margin-bottom: 10px; }
      .block-admin .admin-signature .admin-signature__infos .infos__poste {
        font-family: "Inter Regular", sans-serif;
        color: #212121;
        font-size: 16px; }
    .block-admin .admin-signature .admin-signature__btn-delete p {
      color: #212121;
      font-family: "Inter Medium", sans-serif;
      font-size: 14px;
      opacity: 0.7;
      -webkit-transition: opacity 250ms;
      transition: opacity 250ms;
      cursor: pointer; }
      .block-admin .admin-signature .admin-signature__btn-delete p:hover {
        opacity: 1;
        -webkit-transition: opacity 250ms;
        transition: opacity 250ms; }
  .block-admin .block-admin__add {
    margin-top: 50px; }
    .block-admin .block-admin__add label {
      display: flex;
      flex-direction: column; }
      .block-admin .block-admin__add label:not(:last-child) {
        margin-bottom: 30px; }
      .block-admin .block-admin__add label span {
        font-family: "Inter Regular", sans-serif;
        font-size: 14px;
        color: #212121; }
      .block-admin .block-admin__add label input {
        margin-top: 10px;
        outline: none;
        border: medium none currentColor;
        border: initial;
        border-bottom: 1px solid #212121;
        background: transparent;
        font-family: "Inter Regular", sans-serif;
        font-size: 14px; }
    .block-admin .block-admin__add .full {
      width: 100%;
      display: flex;
      justify-content: space-between; }
      .block-admin .block-admin__add .full label {
        width: 49%; }

.btn-submit {
  margin-top: 25px;
  background: transparent;
  border: medium none currentColor;
  border: initial;
  outline: none;
  font-family: "Inter Medium", sans-serif;
  font-size: 16px;
  padding: 0;
  padding-bottom: 5px;
  border-bottom: 1px solid #212121; }
  .btn-submit:after {
    content: "-->";
    position: relative;
    margin-left: 5px;
    -webkit-transition: margin-left 250ms ease-in-out;
    transition: margin-left 250ms ease-in-out; }
  .btn-submit:hover:after {
    margin-left: 10px;
    -webkit-transition: margin-left 250ms ease-in-out;
    transition: margin-left 250ms ease-in-out; }

td,
tr,
th,
tbody {
  margin: 0;
  padding: 0; }

table {
  vertical-align: top; }

a {
  color: inherit;
  text-decoration: none; }

.first-table {
  width: 100%;
  max-width: 500px;
  margin: 0;
  padding-top: 5px;
  margin-bottom: 10px;
  font-family: Arial, sans-serif;
  font-size: 12px;
  display: block;
  border-top: 1px solid #bababa; }
  .first-table .first-table__logo {
    height: 180px;
    width: auto; }
  .first-table .first-table__separator {
    font-size: 1px;
    line-height: 1px;
    border: 0px;
    padding: 0px;
    width: 10px; }
  .first-table .nom_prenom {
    color: #11486f;
    margin: 0;
    font-size: 16px; }
  .first-table .poste {
    color: #323232;
    margin: 0;
    margin-top: 3px;
    font-size: 11px; }
  .first-table .first-table__infos {
    color: #000000;
    margin: 0;
    margin-top: 10px;
    font-size: 14px; }
    .first-table .first-table__infos .infos__number {
      color: #000000;
      text-decoration: underline;
      font-weight: bold;
      opacity: 0.7; }
  .first-table .urgence-constat {
    color: #cd4142;
    margin: 0;
    font-size: 14px;
    font-weight: bold;
    margin-top: 10px;
    display: block; }
  .first-table .link-blue {
    color: #11486f;
    margin: 0;
    font-size: 13px; }

.second-table {
  width: 500px;
  max-width: 500px;
  margin: 0;
  padding-top: 5px;
  font-family: Arial, sans-serif;
  font-size: 11px;
  display: block;
  border-top: 1px solid #f2f2f2; }
  .second-table .second-table__bandeau {
    color: #000000;
    font-size: 12px;
    margin: 0;
    margin-top: 5px;
    line-height: 1.4; }

.login-form {
  width: 400px;
  margin: 0 auto;
  margin-top: 10em;
  padding: 30px 35px;
  background: #eeeeee;
  border-radius: 5px; }
  .login-form .form-title {
    font-family: "Inter Bold", sans-serif;
    font-size: 24px;
    color: #212121;
    margin-bottom: 40px; }
  .login-form label {
    width: 100%;
    display: flex;
    flex-direction: column; }
    .login-form label span {
      font-family: "Inter Regular", sans-serif;
      font-size: 14px;
      color: #212121; }
    .login-form label input {
      margin-top: 10px;
      outline: none;
      border: medium none currentColor;
      border: initial;
      border-bottom: 1px solid #212121;
      background: transparent;
      font-family: "Inter Regular", sans-serif;
      font-size: 14px; }
  @media (max-width: 480px) {
    .login-form {
      width: 85vw; } }

